import React, { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { marked } from "marked";

import cx from "classname";
import styles from "./chat.module.css";

export default ({ className, aiName, header, footer, apiConfig, messages, onSendMessage, showEllipsis }) => {
  const [ nextMessage, setNextMessage ] = useState("");
  const [ sendingMessage, setSendingMessage ] = useState(null);

  const names = {
    user: "You",
    assistant: aiName,
    tool: "Api"
  };
  const icons = {
    user: "person",
    assistant: "neurology",
    tool: "api"
  };

  var messagesToShow = messages;
  if(sendingMessage) {
    messagesToShow = [
      ...messagesToShow, {
        role: "user",
        content: sendingMessage
      }
    ];
  }
  if(!!sendingMessage || showEllipsis) {
    messagesToShow = [ ...messagesToShow, {
      role: "assistant",
      content: "..."
    } ];
  }

  const sendMessage = async () => {
    setSendingMessage(nextMessage);
    try {
      setNextMessage("");
      await onSendMessage(nextMessage);
    } finally {
      setSendingMessage(null);
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.scrollIntoView();
    inputRef.current?.focus();
  }, [ messages ]);

  return (
    <div class={cx(styles.root, className)}>
      { header }
      { header && <hr/> }
      { messagesToShow.map(({ role, content }, index) =>
        <div key={index} class={ cx(styles.message, styles[role]) }>
          <span class={ cx("material-symbols-outlined", styles.personIcon) }>{ icons[role] }</span>
          <div>
            <p class={styles.name}>{ names[role] }</p>
            <div dangerouslySetInnerHTML={{__html: marked.parse(content || "")}}></div>
          </div>
        </div>
      ) }
      <div class={styles.writeMessage}>
        <input
          value={nextMessage}
          onChange={e => setNextMessage(e.target.value)}
          onKeyDown={e => e.key === "Enter" ? sendMessage() : null}
          disabled={!!sendingMessage}
          placeholder={ !sendingMessage ? "Ask a question" : "..." }
          ref={inputRef}
        />
        <button
          onClick={() => sendMessage()}
          disabled={!!sendingMessage}
        >Send</button>
      </div>
      { footer && <hr/> }
      { footer }
    </div>
  );
}
