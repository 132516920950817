import React, { useState, useEffect } from 'react';
import cx from "classname";
import Popup from 'reactjs-popup';

import Chat from "./chat";
import { InlineAuthEditor } from "./auth-editor";
import Share from "./share";

import { getAccessToken, loggedIn, login, logout, retry, csrf } from "./auth";

import styles from "./Admin.module.css";

import { API_ROOT } from "./env";

const Login = ({ setAuth }) => {
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ loginError, setLoginError ] = useState(null);

  return (
    <div class={styles.login}>
      <h2>Login</h2>
      <label><span>Username</span><input type="text" value={username} onChange={e => setUsername(e.target.value)}/></label>
      <label><span>Password</span><input type="password" value={password} onChange={e => setPassword(e.target.value)}/></label>
      { loginError && <p>{ loginError }</p> }
      <button
        onClick={ async () => {
          const result = await login(username, password);
          if(result.error) {
            setLoginError(result.error)
          } else {
            setAuth(result);
          }
        }}
        disabled={!username || !password}
      >Login</button>
    </div>
  );
};

const CreateApiConfig = ({ onCreateApi }) => {
  const [ docRoot, setDocRoot ] = useState("");
  const [ creatingChatBot, setCreatingChatBot ] = useState(false);

  return (
    <div class={styles.createApiConfig}>
      <p>Enter the URL of your API documentation</p>
      <input
        value={docRoot}
        onChange={event => setDocRoot(event.target.value)}
        type="text"
        disabled={creatingChatBot}
      />
      <button
        onClick={async () => {
          setCreatingChatBot(true);
          await onCreateApi(docRoot);
          setCreatingChatBot(false);
        }}
        disabled={!docRoot || creatingChatBot}
      >Create Chatbot</button>
    </div>
  );
};

const EditDeclaredFunctionConfig = ({ func, onChange, onDelete }) => {
  const onLocationChanged = i => e => onChange({
    ...func,
    parameters: func.parameters.toSpliced(
      i, 1, {
        ...func.parameters[i],
        location: e.target.value
      }
    )
  });

  return (
    <div class={styles.editDeclaredFunctionConfig}>
      <ExpandableElement
        title={
          <label>
            <input
              value={func.name}
              onChange={e => onChange({
                ...func,
                name: e.target.value
              })}
            />
          </label>
        }
      >
        <label>
          <p>Description</p>
          <textarea
            value={func.description}
            onChange={e => onChange({
              ...func,
              description: e.target.value
            })}
          />
        </label>
        <label>
          <p>Endpoint</p>
          <input
            value={func.endpoint}
            onChange={e => onChange({
              ...func,
              endpoint: e.target.value
            })}
          />
        </label>
        <label>
          <p>Method</p>
          <select
            value={func.method}
            onChange={e => onChange({
              ...func,
              method: e.target.value
            })}
          >
            <option>GET</option>
            <option>PUT</option>
            <option>PATCH</option>
            <option>POST</option>
            <option>DELETE</option>
          </select>
        </label>
        <button
          onClick={() => onChange({
            ...func,
            parameters: [{
                name: "",
                description: "",
                location: "querystring"
              }, ...func.parameters
            ]
          })}
        >Add Parameter</button>
        { func.parameters.map((p, i) =>
          <div key={i} class={styles.editParameterConfig}>
            <ExpandableElement
              title={
                <label>
                  <input
                    value={p.name}
                    onChange={e => onChange({
                      ...func,
                      parameters: func.parameters.toSpliced(
                        i, 1, {
                          ...func.parameters[i],
                          name: e.target.value
                        }
                      )
                    })}
                  />
                </label>
              }
            >
              <label>
                <p>Description</p>
                <input
                  value={p.description}
                  onChange={e => onChange({
                    ...func,
                    parameters: func.parameters.toSpliced(
                      i, 1, {
                        ...func.parameters[i],
                        description: e.target.value
                      }
                    )
                  })}
                />
              </label>
              <label>
                <input
                  type="radio"
                  name={`${func.url}-${i}-location`}
                  value="url"
                  checked={ p.location === "url" }
                  onChange={ onLocationChanged(i) }
                />
                <span>Splice into URL</span>
              </label>
              <label>
                <input
                  type="radio"
                  name={`${func.url}-${i}-location`}
                  value="querystring"
                  checked={ p.location === "querystring" }
                  onChange={ onLocationChanged(i) }
                />
                <span>Add to querystring</span>
              </label>
              <p
                onClick={e => onChange({
                  ...func,
                  parameters: func.parameters.toSpliced(i, 1)
                })}
                class={styles.delete}
              >
                Delete
              </p>
            </ExpandableElement>
          </div>
        )}
        <label>
          <p>JSONata Modifier</p>
          <textarea
            value={func.jsonata_modifier}
            onChange={e => onChange({
              ...func,
              jsonata_modifier: e.target.value
            })}
          />
        </label>
        <p onClick={onDelete} class={styles.delete}>
          Delete
        </p>
      </ExpandableElement>
    </div>
  );
};

const EditApiConfig = ({ editingApi, onSave, onCancel }) => {
  const [ name, setName ] = useState(editingApi.name);
  const [ description, setDescription ] = useState(editingApi.description);
  const [ systemPrompt, setSystemPrompt ] = useState(editingApi.system_prompt);
  const [ auth, setAuth ] = useState(editingApi.auth_config);
  const [ declaredFunctions, setDeclaredFunctions ] = useState(editingApi.declared_functions);

  return (
    <div class={styles.editApiConfig}>
      <input
        value={ name }
        onChange={e => setName(e.target.value)}
        placeholder="The public facing name of the chatbot"
      />
      <p>An intro for the user</p>
      <textarea
        value={ description }
        onChange={e => setDescription(e.target.value)}
        placeholder="A human readable description of what the chatbot can do"
      />
      <p>A prompt for the AI</p>
      <textarea
        value={ systemPrompt }
        onChange={e => setSystemPrompt(e.target.value)}
        placeholder="The GPT System Prompt that configures the Chatbots behaviour, tone of voice and language"
      />
      <p>API Functions</p>
      <button
        onClick={() => setDeclaredFunctions([{
            name: "",
            description: "",
            endpoint: "",
            method: "GET",
            parameters: []
          }, ...declaredFunctions
        ])}
        className={styles.addFunctionButton}
      >Add Function</button>
      { declaredFunctions.map((func, index) =>
        <div key={index}>
          <EditDeclaredFunctionConfig
            func={func}
            onChange={newFunc => setDeclaredFunctions(declaredFunctions.toSpliced(index, 1, newFunc))}
            onDelete={() => setDeclaredFunctions(declaredFunctions.toSpliced(index, 1))}
          />
        </div>
      ) }
      <InlineAuthEditor
        auth={ auth }
        onChangeAuth={newAuth => setAuth(newAuth)}
      />
      <button
        onClick={() => onSave({
          ...editingApi,
          name,
          description,
          system_prompt: systemPrompt,
          auth_config: auth,
          declared_functions: declaredFunctions
        })}
      >Save</button>
      <button
        onClick={() => onCancel()}
      >Cancel</button>
    </div>
  );
};

const TestApiConfig = ({ testingApi, auth, setAuth }) => {
  const [ conversation, setConversation ] = useState(null);
  const [ messages, setMessages ] = useState([]);

  useEffect(() => {
    startConversation()
  }, []);

  const startConversation = async () => {
    const response = await retry(auth, setAuth, headers => fetch(API_ROOT + "admin/conversation/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...csrf()
      },
      credentials: "include",
      body: JSON.stringify({
        api_config: testingApi.url
      })
    }));
    if(response.ok) {
      setMessages((await response.json()).messages);
      setConversation(response.headers.get("location"));
    }
  };

  const sendMessage = async (content) => {
    const response = await retry(auth, setAuth, headers => fetch(`${conversation}chat/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...csrf()
      },
      credentials: "include",
      body: JSON.stringify({ content })
    }));
    if(response.ok) {
      setMessages((await response.json()).messages);
    }
  };

  return (
    <Chat
      apiConfig={testingApi}
      messages={messages}
      onSendMessage={sendMessage}
      showEllipsis={!conversation}
    />
  );
};

const ExpandableElement = ({ title, children }) => {
  const [ expanded, setExpanded ] = useState(false);
  return (
    <>
      <div class={styles.expandableTitle}>
        <span
          class={cx("material-symbols-outlined", styles.expander)}
          onClick={() => setExpanded(!expanded)}
        >{ expanded ? "indeterminate_check_box" : "add_box" }</span>
        <div>{title}</div>
      </div>
      { expanded && children }
    </>
  );
};

export default () => {
  const [ auth, setAuth ] = useState(null);
  const [ loadedAuth, setLoadedAuth ] = useState(false);
  const [ apis, setApis ] = useState([]);
  const [ creatingApi, setCreatingApi ] = useState(false);
  const [ editingApi, setEditingApi ] = useState(null);
  const [ testingApi, setTestingApi ] = useState(null);
  const [ publishingApi, setPublishingApi ] = useState(null);

  useEffect(
    () => { !!auth && loadApiConfigs() },
    [ auth?.access ]
  );

  useEffect(
    () => {
      const access = localStorage.getItem("access");
      const refresh = localStorage.getItem("refresh");
      if(access && refresh) {
        setAuth({ access, refresh });
      }
      // Delay this until we've had a chance to set the auth, to avoid flickering
      setLoadedAuth(true);
    }, []
  );

  useEffect(
    () => {
      if(loadedAuth) {
        if(auth) {
          localStorage.setItem("access", auth.access);
          localStorage.setItem("refresh", auth.refresh);
        } else {
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");          
        }
      }
    }, [ auth?.access, auth?.refresh ]
  );

  const loadApiConfigs = async () => {
    const response = await retry(auth, setAuth, headers => fetch(
      `${API_ROOT}admin/api-config/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        credentials: "include"
      }
    ));
    if(response?.ok) {
      setApis(await response.json());
    }
  };

  const createApiConfig = async (url) => {
    const response = await retry(auth, setAuth, headers => fetch(`${API_ROOT}admin/api-config/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...csrf(),
      },
      credentials: "include",
      body: JSON.stringify({ root_url: url })
    }));
    if(response.ok) {
      const newApi = (await response.json()).url;
      await loadApiConfigs();
      setEditingApi(newApi);
      setCreatingApi(false);
    }
  };

  const onSaveApi = async (api) => {
    const response = await retry(auth, setAuth, headers => fetch(api.url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...csrf(),
      },
      credentials: "include",
      body: JSON.stringify(api)
    }));
    if(response.ok) {
      setEditingApi(null);
      await loadApiConfigs();
    }
  };

  const onDeleteApi = async (url) => {
    const response = await retry(auth, setAuth, headers => fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...csrf(),
      },
      credentials: "include"
    }));
    if(response.ok) {
      await loadApiConfigs();
    }
  };

/*  const deleteDeclaredFunction = async (url) => {
    const response = await retry(auth, setAuth, headers => fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...headers,
        ...csrf(),
      },
      credentials: "include"
    }));
    if(response.ok) {
      await loadApiConfigs();
    }
  };*/

  const editingApiObject = apis.find(a => a.url === editingApi);
  const testingApiObject = apis.find(a => a.url === testingApi);
  const publishingApiObject = apis.find(a => a.url === publishingApi);

  return (
    <div class={styles.root}>
      <div class={styles.header}>
        <h2>API Bot Builder</h2>
        <p
          onClick={() => setAuth(null)}
        >Logout</p>
      </div>
      <Popup
        open={loadedAuth && !auth}
        closeOnDocumentClick={false}
      >
        <Login
          setAuth={setAuth}
        />
      </Popup>
      <Popup
        open={ creatingApi }
        onClose={ () => {
          setCreatingApi(false);
        }}
        modal
        nested
      >
        <CreateApiConfig
          onCreateApi={(url) => createApiConfig(url)}
        />
      </Popup>
      <Popup
        open={ !!editingApiObject }
        onClose={ () => {
          setEditingApi(null);
        }}
        modal
        nested
      >
        { editingApiObject &&
          <EditApiConfig
            editingApi={ editingApiObject }
            onSave={onSaveApi}
            onCancel={() => setEditingApi(null)}
          />
        }
      </Popup>
      <Popup
        open={ !!testingApi }
        onClose={ () => {
          setTestingApi(null);
        }}
        modal
        nested
      >
        { testingApiObject &&
          <TestApiConfig
            testingApi={ testingApiObject }
            auth={auth}
            setAuth={setAuth}
          />
        }
      </Popup>
      <Popup
        open={ !!publishingApi }
        onClose={ () => {
          setPublishingApi(null);
        }}
        modal
        nested
      >
        { publishingApiObject &&
          <Share
            api={ publishingApiObject }
          />
        }
      </Popup>
      <div class={styles.content}>
        <ul>
          { apis.map(api => 
            <li>
              <p>{ api.name }</p>
              <p>{ api.description }</p>
              <button
                onClick={() => setEditingApi(api.url)}
              >Edit</button>
              <button
                onClick={() => setTestingApi(api.url)}
              >Test</button>
              <button
                onClick={() => setPublishingApi(api.url)}
              >Publish</button>
              <button
                onClick={() => {
                  if(window.confirm(`Do you want to delete ${api.name}?`)) {
                    onDeleteApi(api.url);
                  }
                }}
              >Delete</button>
            </li>
          )}
        </ul>
        <button
          onClick={() => setCreatingApi(true)}
        >Create ChatBot</button>
      </div>
    </div>
  );
};
