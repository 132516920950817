import React, { useState, useEffect, useRef } from 'react';

import styles from "./share.module.css";

const LinkCopier = ({ children }) => {
  const [ copied, setCopied ] = useState(false);
  return (
    <div>
      <a href={children} target="_blank">{ children }</a>
      <button
        onClick={() => {
          navigator.clipboard.writeText(children);
          setCopied(true);
        }}
        disabled={copied}
      >{ !copied ? "Copy" : "Copied" }</button>
    </div>
  );
};

const CodeCopier = ({ children }) => {
  const [ copied, setCopied ] = useState(false);
  return (
    <div>
      <pre><code>{ children }</code></pre>
      <button
        onClick={() => {
          navigator.clipboard.writeText(children);
          setCopied(true);
        }}
        disabled={copied}
      >{ !copied ? "Copy" : "Copied" }</button>
    </div>
  );
}

export default ({ api }) => {
  const link = `http://localhost:3000/?mode=iframe&api=${api.id}`;

  const PAGES = {
    "Embedded Iframe": (
      <div>
        <h2>Copy the following code into your website</h2>
        <CodeCopier>
          {`<div class="cfyapi" data-api="${api.id}"></div>
<script src="http://localhost:8000/public/iframe.js"></script>`}
        </CodeCopier>
      </div>
    ),
    "Use a Link": (
      <div>
        <h2>To add a link to your website, use this code</h2>
        <CodeCopier>
          {`<a ref=${link}>Talk to us</a>`}
        </CodeCopier>
        <h2>Or copy this link to your email footer</h2>
        <LinkCopier>{link}</LinkCopier>
      </div>
    )
  };

  const [ selectedPage, setSelectedPage ] = useState(Object.keys(PAGES)[0]);

  return (
    <div class={styles.root}>
      <h1>Share</h1>
      <div class={styles.contents}>
        <div class={styles.left}>
          { Object.keys(PAGES).map((n) => <p onClick={() => setSelectedPage(n)}>{ n }</p> ) }
        </div>
        <div class={styles.right}>
          { PAGES[selectedPage] }
        </div>
      </div>
    </div>
  );
};
