import Cookies from "js-cookie";

import { API_ROOT } from "./env";

export const login = async (username, password) => {
  const response = await fetch(`${API_ROOT}auth/token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({username, password})
  });
  const data = await response.json();
  if(response.ok) {
    return {
      access: data.access,
      refresh: data.refresh
    };
  } else if(response.status === 401) {
    return {
      error: "Incorrect username or password"
    };
  } else {
    return {
      error: "Unknown error"
    };
  }
};

const refresh = async ({ access, refresh }) => {
  const response = await fetch(`${API_ROOT}auth/token/refresh/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refresh })
  });
  const data = await response.json();
  if(response.ok) {
    return {
      access: data.access,
      refresh: data.refresh
    };
  }
  return null;
};

export const csrf = () => {
  return {
    "x-csrftoken": Cookies.get("csrftoken")
  };
};

export const retry = async (auth, setAuth, fetcher) => {
  if(!auth) { return; }
  var response = await fetcher(authHeaders(auth));
  if(response.status === 401) {
    auth = await refresh(auth);
    setAuth(auth);
    if(!auth) { return; }
    response = await fetcher(authHeaders(auth));
  }
  return response;
};

const authHeaders = ({ access }) => {
  return {
    Authorization: `Bearer ${access}`
  }
};
