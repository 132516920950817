import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import cx from "classname";

import { csrf } from "./auth";

import Chat from "./chat";
import { TransactionalAuthEditor } from "./auth-editor";
import styles from "./Demo.module.css";

import { API_ROOT } from "./env";

const Signup = ({ }) => {
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");

  return (
    <div class={styles.login}>
      <h2>Sign Up</h2>
      <label><span>Username</span><input type="text" value={username} onChange={e => setUsername(e.target.value)}/></label>
      <label><span>Password</span><input type="password" value={password} onChange={e => setPassword(e.target.value)}/></label>
      <button
        onClick={ async () => {
          /*const result = await login(username, password);
          if(result.error) {
            setLoginError(result.error)
          } else {
            setAuth(result);
          }*/
        }}
        disabled={!username || !password}
      >Sign Up</button>
    </div>
  );
};

const ContactUs = () => {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSe5NQSaXQQaZYodZI-a7iPRVWCzqK-dKXYGDgzQoDkygoFtmg/viewform?embedded=true"
      width="100%" height="721"
      frameborder="0"
      marginheight="0"
      marginwidth="0">
      Loading...
    </iframe>
  );
}

const EXAMPLE_APIS = {
  "Cat Facts": "https://github.com/wh-iterabb-it/meowfacts",
  "Agify": "https://agify.io/documentation",
  "Bible Search": "https://bible-api.com/",
  "Weather": "https://www.visualcrossing.com/resources/documentation/weather-api/timeline-weather-api/",
  "Inspirational Quotes": "http://forismatic.com/en/api/"
};

function App() {
  const [ initialized, setInitialized ] = useState(false);
  const [ showContactUsPopup, setShowContactUsPopup ] = useState(false);
  const [ docRoot, setDocRoot ] = useState();
  const [ api, setApi ] = useState();
  const [ auth, setAuth ] = useState();
  const [ loadingApi, setLoadingApi ] = useState(false);
  const [ chat, setChat ] = useState([]);
  const [ showSignup, setShowSignup ] = useState(false);
  const [ error, setError ] = useState(null);

  const setErrorFromResponse = (r) => {
    if(r.status === 429) {
      setError("That's enough usage for now");
    } else {
      setError("There was an error");
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  const loadApi = async (docRoot) => {
    const showLoading = setTimeout(() => setLoadingApi(true), 500);
    try {
      setApi(null);
      setChat([]);
      const response = await fetch(API_ROOT + "demo/load-api/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...csrf()
        },
        credentials: "include",
        body: JSON.stringify({
          url: docRoot
        })
      });
      if(response.ok) {
        const api = await response.json();
        setApi(api);
      } else {
        setErrorFromResponse(response);
      }
    } finally {
      clearTimeout(showLoading);
      setLoadingApi(false);
    }
  };

  const getStatus = async () => {
    const response = await fetch(
      API_ROOT + "demo/status/", {
        credentials: "include",
      }
    );
    if(response.ok) {
      const status = await response.json();
      setApi(status.api_config);
      setAuth(status.auth);
      if(status.api_config) {
        setDocRoot(status.api_config.root_url);
      }
      setChat(status.messages);
    } else {
      setErrorFromResponse(response);
    }
    setInitialized(true);
  };

  const saveAuth = async (newAuth) => {
    const response = await fetch(API_ROOT + "demo/auth/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...csrf()
      },
      credentials: "include",
      body: JSON.stringify(newAuth)
    });
    if(response.ok) {
      const backendAuth = await response.json();
      setAuth(backendAuth);
      return backendAuth;
    }
  };

  const sendMessage = async (content) => {
    const response = await fetch(API_ROOT + "demo/chat/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...csrf()
      },
      credentials: "include",
      body: JSON.stringify({ content })
    });
    if(response.ok) {
      setChat(await response.json());
    } else {
      setErrorFromResponse(response);
    }
  };

  if(!initialized) {
    return null;
  }

  return (
    <div class={cx(styles.root, { [styles.apiLoaded]: !!api })}>
      <h1>API Bot Builder</h1>
      <h2>Build an API bot that uses public APIs to answer questions.</h2>
      <p>To get started, just enter the URL of your API documentation</p>
      <input
        value={docRoot}
        onChange={event => setDocRoot(event.target.value)}
        type="text"
        onKeyDown={e => (e.key === "Enter" && docRoot && !loadingApi) ? loadApi(docRoot) : null}
        placeholder="https://"
        disabled={loadingApi}
      />
      <div class={styles.examples}>
        <p>Or try one of these...</p>
        { Object.entries(EXAMPLE_APIS).map(([ name, url ]) =>
          <a
            key={name}
            href="#"
            onClick={() => {
              setDocRoot(url);
              loadApi(url);
            }}
          >{ name } - { url }</a>
        ) }
        <a href="https://github.com/public-apis/public-apis" target="_blank">Or select from hundreds of public APIs</a>
      </div>
      { loadingApi && <p class={styles.loading}>I am reading the API documentation.<br/><br/>It can take 10 - 30 seconds, but it is worth the wait.</p> }
      { api &&
        <Chat
          aiName="AI"
          header={
            <div class={styles.chatHeader}>
              <p>{api.name}</p>
              <p>{api.description}</p>
              <TransactionalAuthEditor
                staticAuth={ api.auth_config }
                dynamicAuth={ auth }
                onChangeDynamicAuth={ saveAuth }
              />
            </div>
          }
          footer={ false && chat.length > 0 &&
            <div>
              <button onClick={() => setShowSignup(true)}>Manage Settings</button>
              <button onClick={() => setShowSignup(true)}>Create Slackbot</button>
              <button onClick={() => setShowSignup(true)}>Embed in Website</button>
              <button onClick={() => setShowSignup(true)}>Other Sharing Options</button>
            </div>
          }
          apiConfig={api}
          messages={chat}
          onSendMessage={(message) => sendMessage(message)}
          className={styles.chat}
        />
      }
      <Popup
        open={showContactUsPopup}
        onClose={() => setShowContactUsPopup(false)}
        className="contactUsPopup"
      >
        <ContactUs />
      </Popup>
      <Popup
        open={showSignup}
        onClose={() => setShowSignup(false)}
      >
        <Signup showSignup={showSignup}/>
      </Popup>
      { error && <h1>{ error }</h1> }
      <a
        href="#"
        onClick={() => setShowContactUsPopup(true)}
        class={styles.contactUsLink}
      >Contact Us</a>
    </div>
  );
}

export default App;
