import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Admin from './Admin';
import Demo from './Demo';
import IFrame from './IFrame';

const searchParams = new URLSearchParams(window.location.search);
const mode = searchParams.get("mode");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    { !mode && <Demo /> }
    { mode === "iframe" && <IFrame /> }
    { mode === "admin" && <Admin /> }
  </>
);
