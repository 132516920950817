import React, { useState, useEffect } from 'react';
import styles from './auth-editor.css';

export const InlineAuthEditor = ({ staticAuth, dynamicAuth, onChangeDynamicAuth }) => {
  const combinedAuth = { ...staticAuth, ...dynamicAuth };
  if(combinedAuth.query_string_apikey_name) {
    return (
      <>
        <p>The API might require an API Key - you can enter one here</p>
        <input
          placeholder="API Key"
          value={ combinedAuth.api_key || "" }
          onChange={(e) => {
            onChangeDynamicAuth({
              ...dynamicAuth,
              api_key: e.target.value || undefined
            });
          }}
        />
        { combinedAuth.api_key &&
          <button
            onClick={async () => {
              await onChangeDynamicAuth({
                ...dynamicAuth,
                api_key: null
              });
            }}
          >Clear</button>
        }
      </>
    );
  }
  return null;
};

export const TransactionalAuthEditor = ({ staticAuth, dynamicAuth, onChangeDynamicAuth }) => {
  const combinedAuth = { ...staticAuth, ...dynamicAuth };
  const [ newAuth, setNewAuth ] = useState(dynamicAuth);

  const disabled = newAuth === dynamicAuth;

  if(combinedAuth.query_string_apikey_name) {
    return (
      <>
        <InlineAuthEditor
          staticAuth={ staticAuth }
          dynamicAuth={ newAuth }
          onChangeDynamicAuth={ setNewAuth }
        />
        <button
          onClick={async () => {
            setNewAuth(await onChangeDynamicAuth(newAuth));
          }}
          disabled={disabled}
        >{ disabled ? "Saved" : "Save" }</button>
      </>
    );
  }
  return null;
};
