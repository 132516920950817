import React, { useState, useEffect } from 'react';
import Chat from "./chat";

import { API_ROOT } from "./env";

const searchParams = new URLSearchParams(window.location.search);

export default () => {
  const [ conversation, setConversation ] = useState();
  const [ chat, setChat ] = useState([]);

  useEffect(() => {
    startConversation()
  }, []);

  const startConversation = async () => {
    const response = await fetch(API_ROOT + "public/conversation/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        api_config: `${API_ROOT}public/api-config/${searchParams.get("api")}/`
      })
    });
    if(response.ok) {
      setChat((await response.json()).messages);
      setConversation(response.headers.get("location"));
    }
  };

  const sendMessage = async (content) => {
    const response = await fetch(`${conversation}chat/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({ content })
    });
    if(response.ok) {
      setChat((await response.json()).messages);
    }
  };

  return (
    <Chat
      aiName="AI"
      messages={chat}
      onSendMessage={(message) => sendMessage(message)}
    />
  )
};
